/*@import url("../src/assets/Aeroport-trial-11/Aeroport-light-italic-trial.otf");*/
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');

@font-face {
  font-family: 'Aeroport';
  src:url('../src/assets/Aeroport-trial-11/Aeroport-black.otf') format('otf');
  font-weight:700;
  font-style:normal;
}

@font-face {
  font-family: 'Aeroport';
  src:url('../src/assets/Aeroport-trial-11/Aeroport-regular-trial.otf') format('otf');
  font-weight:400;
  font-style:normal;
}
@font-face {
  font-family: 'Aeroport';
  src:url('../src/assets/Aeroport-trial-11/Aeroport-monospaced-trial.otf') format('otf');
  font-weight:100;
  font-style:normal;
}
@tailwind base;
@tailwind components;
@tailwind utilities;

.formWidth{
  width: 850px;
}