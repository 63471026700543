.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.backgroungimg{
  background-image: url(assets/home.png);
  background-repeat: no-repeat;
  background-size:cover; 
  
}

.background2{
  background-image: url(assets/group2.png);
  background-repeat: no-repeat;
  background-size:cover;
 
}
.width{
  max-width: 1344px;
 
}
.height{
  max-height: 593px;
  
}
.heightWorkflex{
  max-height: 1310px;
}
.workplaceimg{
  max-width: 608px;
  max-height: 619px;
}
.addedQmaxWidth{
  width: 1070px;
}
.formscreenheight{
  height: 850px;
}

/* Wrapper to control overflow */
.overflow-hidden {
  overflow: hidden;
}

/* Hide scrollbars on the inner scrollable area */
.no-scrollbar-inner {
  display: flex;
  overflow-x: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}

.no-scrollbar-inner::-webkit-scrollbar {
  display: none; /* Chrome, Safari, and Opera */
}


input[type="checkbox"] {
  width: 20px; /* Adjust size as needed */
  height: 20px;
  appearance: none; /* Remove default checkbox styling */
  background-color: #fff; /* Default background color */
  border: 2px solid #ccc; /* Border color */
  border-radius: 50%; /* Make it round */
  outline: none;
  cursor: pointer;
  position: relative; /* Necessary for positioning the dot */
  transition: background-color 0.3s, border-color 0.3s;
}

input[type="checkbox"]:checked {
  background-color: #007bff; /* Blue color when checked */
  border-color: #007bff; /* Border color when checked */
}

input[type="checkbox"]:checked::before {
  content: '';
  display: block;
  width: 8px; /* Size of the inner dot */
  height: 8px;
  background-color: #fff; /* White dot color */
  border-radius: 50%; /* Make the dot round */
  position: absolute; /* Position it relative to the checkbox */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Center the dot inside the checkbox */
}



