/* Background Animation */
@keyframes movingGradient {
    0% {
        background-position: 0% 0%;
    }
    100% {
        background-position: 100% 100%;
    }
}

.bg-moving-gradient {
    background: linear-gradient(45deg, #1e3a8a, #2dd4bf, #34d399);
    background-size: 400% 400%;
    animation: movingGradient 15s ease infinite;
}

/* Fade-in effect for page */
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.animate-fade-in {
    animation: fadeIn 1s ease-in-out;
}

/* Slide-in animation */
@keyframes slideIn {
    from {
        transform: translateY(-20px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.animate-slide-in {
    animation: slideIn 0.8s ease-out;
}

/* Pulsing animation */
@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.05);
    }
    100% {
        transform: scale(1);
    }
}

.bounce-animation {
    animation: pulse 2s infinite;
}

/* Scale-in animation for form */
@keyframes scaleIn {
    from {
        transform: scale(0.8);
        opacity: 0;
    }
    to {
        transform: scale(1);
        opacity: 1;
    }
}

.scale-in-center {
    animation: scaleIn 0.5s ease-in-out;
}

/* Fade-in effect for messages */
@keyframes fadeInMessage {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.animate-fade-in {
    animation: fadeInMessage 0.5s ease-in-out;
}
